@font-face {
  font-family: "farid";
  src: url("fonts/Coolvetica.ttf.woff") format("woff"),
    url("fonts/Coolvetica.ttf.svg#Coolvetica") format("svg"),
    url("fonts/Coolvetica.ttf.eot"),
    url("fonts/Coolvetica.ttf.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "farid";
}
.info-div {
  padding-top: 16px;
}

.intro * {
  line-height: 0.9;
  font-size: 2.7rem;
}

.intro {
  padding-top: 30px;
  text-align: center;
}

.experience .link {
  display: block;
}

.clear-large {
  margin: 50px;
  display: block;
  width: 100%;
}

.clear-xlarge {
  margin: 80px;
  display: block;
  width: 100%;
}

.clear-medium {
  margin: 30px;
  display: block;
  width: 100%;
}

.clear-small {
  margin: 10px;
  display: block;
  width: 100%;
}

.clear-tiny {
  margin: 5px;
  display: block;
  width: 100%;
}
.clear-none {
  margin: 0px;
  display: block;
  width: 100%;
}

.title {
  font-size: 3rem;
  color: #10217d;
  line-height: 1;
}

.description {
  margin-top: 10px;
  font-family: "Open Sans", sans-serif;
}

.skillName {
  display: inline;
  font-size: 0.8rem;
  margin-bottom: 8px;
  font-family: "farid";
  letter-spacing: 2px;
}

.progress {
  height: 1px;
}

.skills > .col-6 {
  margin-top: 23px;
}

#mypicDiv:hover {
  cursor: pointer;
}

.link {
  text-decoration: none;
  color: #2e4450;
}

.link:hover {
  opacity: 0.5;
  text-decoration: none;
  color: #2e4450;
}

.showTime {
  float: right;
}

.experience .showTime {
  font-size: 0.8rem;
  opacity: 0.6;
  float: left;
}

td {
  border: none !important;
}

@media (max-width: 992px) {
  .showTime {
    float: left;
    opacity: 0.6;
    font-size: 0.8rem;
  }
}

.profile-image {
  width: 70%;
  cursor: pointer;
  border-radius: 15px;
  margin-left: 15%;
  margin-right: 15%;
}
@media (max-width: 992px) {
  .profile-image {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media (min-width: 992px) {
  #leftDiv {
    position: fixed;
    max-width: 80%;
  }
}

@media (min-width: 1650px) {
  .container-fluid {
    max-width: 1500px;
  }
}

@media (min-width: 1200px) {
  #leftDiv {
    padding-left: 20px;
  }
}

@media (min-width: 1400px) {
  #leftDiv {
    padding-left: 0px;
  }
}

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #0d1117;
}

*::-webkit-scrollbar-thumb {
  background-color: #2e4450;
  border-radius: 20px;
  border: 3px solid #0d1117;
}
